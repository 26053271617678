import { useNavigation } from "@remix-run/react";
import React from "react";
import { toast } from "react-toastify";
import { ValidationError } from "~/api/utils";
import { usePrevious } from "~/utils/hooks/usePrevious";

type Props = {
    errors?: ValidationError<unknown>
    onError?: () => void
}

const useGeneralErrorHandler = ({ errors, onError }: Props) => {
    const navigation = useNavigation()
    const trottleToastTimeout = React.useRef<ReturnType<typeof setTimeout>>()
    const previousNavState = usePrevious(navigation.state)
    const prevErrorId = usePrevious(errors?.id)

    React.useEffect(() => {
        const isNewError = prevErrorId !== errors?.id
        const comesFromSubmission = previousNavState === "submitting" && navigation.state === "loading"
        if (!isNewError) return;

        if (errors && errors.general && comesFromSubmission) {
            trottleToastTimeout.current !== undefined && 
                clearTimeout(trottleToastTimeout.current)

            trottleToastTimeout.current = setTimeout(() => {
                toast.error(errors.general)
                trottleToastTimeout.current = undefined
            }, 300);
        }
        if (errors && comesFromSubmission) {
            onError?.()
        }
    }, [errors?.id, onError, navigation.state]);
}

export default useGeneralErrorHandler;